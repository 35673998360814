.intro {
  animation: fadeAppear 1s 0.2s;
}

.heroName{
    line-height: 1.5em;
    animation: fadeAppear 1.3s 0.3s;

    .emphasize {
        &::after {
            content: '';
            position: absolute;
            bottom: 0.7rem;
            left: 0;
            width: 100%;
            height: 0.35rem;
            border-radius: 1rem;
            background-image: linear-gradient(to right, theme("colors.orange.light"), theme("colors.orange.dark"));
            box-shadow: 0 0 1rem theme("colors.orange.dark");
            animation: growHorizontal 1.3s cubic-bezier(0.9, 0, 0.5, 0.9) 0.8s;
        }
    }
}


@keyframes fadeAppear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes growHorizontal {
    from {
      width: 0;
    }
    to {
      width: 98%; 
    }
  }

