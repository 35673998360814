@font-face {
    font-family: 'Calibre';
    src: local('Calibre Light'), local('Calibre-Light'),
    url('Calibre-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Thin'), local('Calibre-Thin'),
        url('Calibre-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Light Italic'), local('Calibre-LightItalic'),
        url('Calibre-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Medium Italic'), local('Calibre-MediumItalic'),
        url('Calibre-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Black Italic'), local('Calibre-BlackItalic'),
        url('Calibre-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Semibold'), local('Calibre-Semibold'),
        url('Calibre-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Black'), local('Calibre-Black'),
        url('Calibre-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Medium'), local('Calibre-Medium'),
        url('Calibre-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Regular Italic'), local('Calibre-RegularItalic'),
        url('Calibre-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Bold Italic'), local('Calibre-BoldItalic'),
        url('Calibre-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Bold'), local('Calibre-Bold'),
        url('Calibre-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre'), local('Calibre-Regular'),
        url('Calibre-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Semibold Italic'), local('Calibre-SemiboldItalic'),
        url('Calibre-SemiboldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: local('Calibre Thin Italic'), local('Calibre-ThinItalic'),
        url('Calibre-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

