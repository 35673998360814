.profile {
  display: inline-flex;
  padding: 1.7rem 0 1rem 0;
  gap: 1.5rem;

  & > a {
    &:hover,
    &:focus {
      filter: drop-shadow(0 0 1rem theme("colors.orange.light"));
      filter: brightness(1.1);
      svg {
        stroke: theme("colors.orange.light");  
      }
    }
  }
}
