@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Bold'), local('JetBrainsMono-Bold'),
        url('JetBrainsMono-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Extra Bold'), local('JetBrainsMono-ExtraBold'),
        url('JetBrainsMono-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Medium'), local('JetBrainsMono-Medium'),
        url('JetBrainsMono-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Regular'), local('JetBrainsMono-Regular'),
        url('JetBrainsMono-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono ExtBd Ita'), local('JetBrainsMono-ExtraBoldItalic'),
        url('JetBrainsMono-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Medium Italic'), local('JetBrainsMono-MediumItalic'),
        url('JetBrainsMono-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Bold Italic'), local('JetBrainsMono-BoldItalic'),
        url('JetBrainsMono-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Italic'), local('JetBrainsMono-Italic'),
        url('JetBrainsMono-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

